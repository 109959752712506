import React, {useState, useContext, useRef} from 'react'
import { navigate } from 'gatsby';
import {InlineNotification} from 'gatsby-theme-carbon';
import { Button, Modal } from "carbon-components-react";
import TextQuestion from '../components/TextQuestion'
import FormattedText from '../components/FormattedText'
import CheckboxQuestion from '../components/CheckboxQuestion'
import AddressQuestion from '../components/AddressQuestion'
import { SubmitButtonComponent, EntityContext, Entity } from '@parallelpublicworks/entitysync';
import { HeedContext } from '../gatsby-theme-carbon/components/Layout';
import { InlineLoading } from 'carbon-components-react'

const ModalComponent = SubmitButtonComponent(({onClick, disabled, ...props}) => {
  const { cancel } = useContext(EntityContext);

  const onRequestClose = () => {
    if(!disabled){
      if(props.onRequestClose){
        props.onRequestClose();
      }
      cancel();
    }
  };

  const onRequestSubmit = (e) => {
    onClick(e);
  };
  
  let _props = props
  delete _props.setModalOpen
  delete _props.setModalOpen

  return (
    <Modal key="modal" {...props} onRequestSubmit={onRequestSubmit} onRequestClose={onRequestClose} primaryButtonDisabled={disabled}>
      {props.children}
    </Modal>
  );
});
  

function EligibilityForm({questions, description, form_id}) {
    const [modalOpen, setModalOpen] = useState(false)
    const [touched, _setTouched] = useState({})
    const touchedRef = useRef(touched)
    const [errors, _setErrors] = useState({})
    const errorsRef = useRef(errors)
    const [submissionInvalid, setSubmissionInvalid] = useState(false)

    const heed = useContext(HeedContext);

    const setTouched = (val) => {
      touchedRef.current = val
      _setTouched(val)
    }

    const setErrors = (val) => {
      errorsRef.current = val
      _setErrors(val)
    }

    const setHasError = (value = 'Invalid field', id = '') => {
      if(typeof value === 'string') {
        setErrors({
          ...errorsRef.current,
          [id]: value
        })
      } else {
        let previousErrors = {...errorsRef.current}
        delete previousErrors[id]
        setErrors(previousErrors)
      }
    }

    const setIsTouched = (value = false, id = '') => {
      if(value) {
        setTouched({
          ...touchedRef.current,
          [id]: true
        })
      } else {
        let prevTouched = {...touchedRef.current}
        delete prevTouched[id]
        setTouched(prevTouched)
      }
    }

    const onSubmit = async (e, unsavedChanges, entityData) => {
      if(unsavedChanges){
        if(Object.keys(errors).length > 0){
          e.preventDefault();
          setSubmissionInvalid(true)
          for (const q of questions) {
            setIsTouched(true, q.drupal_id);
          }
        }else{
          if(!unsavedChanges.attributes) unsavedChanges.attributes = {}
      
          unsavedChanges.attributes.field_is_eligible = true;
      
          if(heed?.setUser){
            heed.setUser({...heed.user, is_eligible: true})
          }
          setSubmissionInvalid(false)
        }
      }else{
        setSubmissionInvalid(true)
        e.preventDefault();
      }
    }

    let btn = <InlineLoading description="Loading..." status='active' />;
    if(!heed.loadingAnswers){
      if(heed?.answers?.all_done){
        btn = <Button onClick={(e) => navigate('/application')} disabled={!heed?.user?.id}>See your tasks</Button>
      }else if(heed?.user?.is_eligible){
        btn = <Button onClick={(e) => navigate('/application-list')}  disabled={!heed?.user?.id}>Start or see your nominations</Button>
      }else{
        btn = <Button onClick={(e) => setModalOpen(true)} disabled={!heed?.user?.id}>Check Eligibility</Button>
      }
    }

    const the_source = heed.user ? {id: heed.user.id } : null
    if(!the_source) return <></>

    return (
        <>
            {btn}
            {heed?.user?.id && <Entity key="entity" type="user--user" source={the_source} componentId="user-entity">
                <ModalComponent 
                    key="modal" 
                    onRequestClose={() => {
                      setModalOpen(false)
                    }}
                    modalHeading="Check Eligibility"
                    primaryButtonText="Submit"
                    secondaryButtonText="Cancel"
                    size="sm"
                    setModalOpen={setModalOpen}
                    onSubmit={onSubmit}
                    open={modalOpen}
                    passiveModal={heed.user.is_eligible}
                    
                >
                    {Object.keys(errors).length > 0 && submissionInvalid && <InlineNotification kind="error"><p>Please review the invalid fields</p></InlineNotification>}
                    {heed.user.is_eligible && <InlineNotification kind="success"><p>You may continue with the application process</p></InlineNotification>}
                    {!heed.user.is_eligible && description && description.length > 0 && <div dangerouslySetInnerHTML={{__html: description}}></div>}
                    <div id={`questions`} className={`${heed.user.is_eligible ? 'hide' : ''}`}>
                      { 
                          questions.map((question, index) => {
                            const is_required = question.field_is_required === true;
                            switch (question.internal?.type) {
                              case 'node__text_question':
                                  return (
                                    <TextQuestion
                                      key={question.drupal_id}
                                      type={question.field_type}
                                      setError={value => setHasError(value, question.drupal_id)}
                                      setTouched={value => setIsTouched(value, question.drupal_id)}
                                      error={errors[question.drupal_id]}
                                      touched={touched[question.drupal_id] === true}
                                      question={question}
                                      field={`field_${question.field_identifier}`}
                                      required={is_required}
                                    />
                                  )
                              case 'node__checkbox_question':
                                  return (
                                    <CheckboxQuestion
                                      key={question.drupal_id}
                                      setError={value => setHasError(value, question.drupal_id)}
                                      setTouched={value => setIsTouched(value, question.drupal_id)}
                                      error={errors[question.drupal_id]}
                                      touched={touched[question.drupal_id] === true}
                                      question={question}
                                      field={`field_${question.field_identifier}`}
                                      required={is_required}
                                    />
                                  )
                              case 'node__formatted_text':
                                return (
                                  <FormattedText
                                    key={question.drupal_id}
                                    body={question.body}
                                  />
                                )
                              case 'node__address_question':
                                return (
                                  <AddressQuestion
                                    key={question.drupal_id}
                                    setError={value => setHasError(value, question.drupal_id)}
                                    setTouched={value => setIsTouched(value, question.drupal_id)}
                                    error={errors[question.drupal_id]}
                                    touched={touched[question.drupal_id] === true}
                                    question={question}
                                    field={question.field_identifier}
                                    required={is_required}
                                  />
                                )
                              default: 
                                  return (<></>)
                                }
                          })
                      }
                    </div>
                </ModalComponent>
            </Entity>}
        </>
    )
}

export default EligibilityForm
